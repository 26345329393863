// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cont {
  background-image: url('dots.jpg');
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
  font-weight: 400;
  height: 100%;
  float: left;
  width: 100%;
  font-size: 12px;
  padding: 20px 5%;
  text-align: center;
}

.cont-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 70%;
}

.cont h2 {
  font-weight: 800;
}

.icon {
  font-size: 100px;
}

.color-green {
  color: green;
}

.color-red {
  color: red;
}

.color-yellow {
  color: rgb(255, 166, 0);
}`, "",{"version":3,"sources":["webpack://./apps/web/src/app/shared/pages/info-alert/info-alert.page.scss"],"names":[],"mappings":"AAAA;EACE,iCAAA;EACA,kBAAA;EACA,8BAAA;EACA,gBAAA;EACA,YAAA;EACA,WAAA;EACA,WAAA;EACA,eAAA;EACA,gBAAA;EACA,kBAAA;AACF;;AACA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;AAEF;;AAAA;EACE,gBAAA;AAGF;;AADA;EACE,gBAAA;AAIF;;AADA;EACE,YAAA;AAIF;;AAFA;EACE,UAAA;AAKF;;AAHA;EACE,uBAAA;AAMF","sourcesContent":[".cont {\r\n  background-image: url('../../../../assets/images/dots.jpg');\r\n  overflow-x: hidden;\r\n  -webkit-text-size-adjust: 100%;\r\n  font-weight: 400;\r\n  height: 100%;\r\n  float: left;\r\n  width: 100%;\r\n  font-size: 12px;\r\n  padding: 20px 5%;\r\n  text-align: center;\r\n}\r\n.cont-info {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: space-between;\r\n  height: 70%;\r\n}\r\n.cont h2 {\r\n  font-weight: 800;\r\n}\r\n.icon {\r\n  font-size: 100px;\r\n  // margin-top: 20px;\r\n}\r\n.color-green {\r\n  color: green;\r\n}\r\n.color-red {\r\n  color: red;\r\n}\r\n.color-yellow {\r\n  color: rgb(255, 166, 0);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
