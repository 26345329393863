import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@bob/api';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
  console.warn('🚨 Console output is disabled on production!');
  console.log = function (): void {};
  console.debug = function (): void {};
  console.warn = function (): void {};
  console.info = function (): void {};
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err: any) => console.log(err));
