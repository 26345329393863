import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { GoogleService, LanguageService } from '@bob/service';
import { Platform } from '@ionic/angular';
import { register } from 'swiper/element/bundle';
import { timer } from 'rxjs';

register();

@Component({
  selector: 'app-bob-frontend-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  standalone: false
})
export class AppComponent {
  public showSplash = true;
  private flag = true;
  public googleMapFlag = false;
  public googleMapError = false;

  constructor(private platform: Platform, private languageService: LanguageService, private httpClient: HttpClient, private googleService: GoogleService) {
    // this.storage.create();
    this.initializeApp();
    this.googleService.GoogleInit().subscribe(flag => {
      this.googleMapFlag = flag;
    });
  }
  async initializeApp() {
    this.flag = false;
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault(); // Sorulmalı
      this.languageService.setInitialAppLanguage();
      timer(700).subscribe(() => (this.showSplash = false));
    });
  }
}
