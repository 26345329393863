import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

export enum Direction {
  add = 0,
  remove = 1
}

enum SpinnerType {
  bubbles = 'bubbles',
  circles = 'circles',
  circular = 'circular',
  crescent = 'crescent',
  dots = 'dots',
  lines = 'lines',
  linesSmall = 'lines-small',
  linesSharp = 'lines-sharp',
  linesSharpSmall = 'lines-sharp-small'
}

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private debugMode = false;
  private _requestCounter = 0;
  private _loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading$: Observable<boolean> = this._loading.asObservable();
  public except: string[] = [];
  private loadingSubscription?: Subscription;
  private loadingElement: any;

  constructor(public loadingController: LoadingController) {}

  private async initializeSpinner(): Promise<void> {
    this.loadingElement = await this.loadingController
      .create({
        // spinner: SpinnerType.bubbles,
        message: 'Loading...',
        translucent: false,
        cssClass: 'custom-loading2',
        showBackdrop: false,
        backdropDismiss: false,
        duration: 0,
        animated: true
      })
      .then(res => {
        if (this.debugMode) {
          console.log(`%c Spinner.Present`, `background: #222; color: #bada55`);
        }

        this.loadingSubscription = this.loading$.subscribe(show => {
          if (show) {
            res.present().then(
              () => {},
              () => {
                res.dismiss();
                if (this.loadingSubscription) this.loadingSubscription.unsubscribe();
                this.loadingElement = undefined;
              }
            );
          } else {
            res.dismiss().then(() => {});
          }
        });
      });

    if (this.debugMode) {
      console.log(`%c Spinner.Initialized`, `background: #222; color: #bada55`);
    }
  }

  // Show the loader for infinite time
  showSpinner(message?: string): void {
    if (this.debugMode) console.log('showSpinner');
    if (!this.loadingElement) this.initializeSpinner();

    this._loading.next(true);

    // if (this.loadingElement) {
    //   // this.loadingElement.style.display = 'block'; // Show spinner
    //   this.loadingElement.present().catch(err => {
    //     if (this.debugMode) {
    //       console.error('Error presenting spinner:', err);
    //     }
    //   });
    // }

    // this.loadingController
    //   .create({
    //     // spinner: SpinnerType.bubbles,
    //     message: message,
    //     translucent: false,
    //     cssClass: 'custom-loading2',
    //     showBackdrop: false,
    //     backdropDismiss: false,
    //     duration: 0,
    //     animated: true
    //     // mode: 'md'
    //   })
    //   .then(res => {
    //     if (this.debugMode) {
    //       console.log(`%c Spinner.Present`, `background: #222; color: #bada55`);
    //     }
    //     res.present().then(() => {
    //       if (this.debugMode) {
    //         console.log(`%c Spinner.Present - x`, `background: #222; color: #bada55`);
    //       }

    //       // this.loadingSubscription = this.loading$.subscribe(show => {
    //       //   if (!show) {
    //       //     console.log('xxxxxxxxxxxxxxxxx');

    //       //     this.loadingController.dismiss();
    //       //     this.loadingSubscription.unsubscribe();
    //       //   }
    //       // });
    //     });
    //   });
  }

  // Hide the loader if already created otherwise return error
  hideSpinner(): void {
    if (this.debugMode) console.log('hideSpinner');
    this._loading.next(false);

    // if (this.loadingElement) {
    //   this.loadingElement.dismiss();
    // }

    // this.loadingController
    //   .dismiss()
    //   .then(() => {
    //     if (this.debugMode) {
    //       console.log(`%c Spinner.Dismiss`, `background: #222; color: #ff5555`);
    //     }
    //   })
    //   .catch(ex => {
    //     if (this.debugMode) {
    //       console.log(`%c Spinner.Dismiss Ex:`, `background: #222; color: #ff5555`, ex);
    //     }
    //   });
  }

  visible(req: HttpRequest<unknown>, direction: Direction) {
    const yasakli = this.except.some(x => req.url.indexOf(x) !== -1);
    const httpVar = req.url.indexOf('http') !== -1;

    if (httpVar && !yasakli) {
      if (direction === Direction.add) {
        this._requestCounter++;

        if (this._requestCounter === 1) {
          this.showSpinner(req.url);
        }
      }

      if (direction === Direction.remove) {
        this._requestCounter--;

        if (this._requestCounter === 0 && this._loading.value) {
          this.hideSpinner();
        }
      }
    }

    if (this.debugMode) {
      const isaretciRengi = direction === Direction.add ? '#bada55' : '#ff5555';
      const operator = direction === Direction.add ? '+' : '-';
      console.log(`%c ${operator} ${this._requestCounter}  %c (${req.url})`, `background: #222; color: ${isaretciRengi}`, 'background: #222; color: #995555');
    }
  }
}
