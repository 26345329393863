import { Component, Input } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';

@Component({
  selector: 'app-info-alert',
  templateUrl: './info-alert.page.html',
  styleUrls: ['./info-alert.page.scss'],
  standalone: false
})
export class InfoAlertPage {
  @Input() public messageType!: string;
  @Input() public message!: string;
  @Input() private route!: string;
  @Input() public title!: string;
  @Input() private isRoute = true;

  constructor(public alertModalController: ModalController, public navCtrl: NavController) {}

  dismiss() {
    this.alertModalController.dismiss();
    if (this.isRoute === true) {
      this.navCtrl.navigateRoot(this.route);
    }
  }
}
