import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HizmetOdemeHesapModel, HizmetOdemeHesapResDetayModel } from '../models/webse.model';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WebseService {
  constructor(private _httpClient: HttpClient) {}

  hizmetOdemeHesap(model: HizmetOdemeHesapModel): Promise<HizmetOdemeHesapResDetayModel[]> {
    return lastValueFrom(this._httpClient.post<HizmetOdemeHesapResDetayModel[]>('/api/WebSe/HizmetOdemeHesap', model));
  }
}
