import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  IyzicoPaymentResponseModel,
  InvoicesModel,
  IyzicoPaymentModel,
  JobsModel,
  RequestSavedCreditCardsResponse,
  SavedCreditCardsReq,
  UserKeyRespModel,
  AdvantageModelOther,
  AdvantageModel,
  AdvantageResult,
  CarSelectModel,
  GetVehicleListModel,
  DeleteVehicleModel,
  VehicleBrands,
  VehicleModels,
  VehicleColors,
  VehicleModel
} from '../models/bob-vale.model';
import { BaseResponse } from '../models/base.model';

@Injectable({
  providedIn: 'root'
})
export class BobvaleService {
  constructor(private _httpClient: HttpClient) {}

  getContinuesJobs(telNo: string): Observable<JobsModel[]> {
    const model = {
      telefon: telNo
    };
    return this._httpClient.post<JobsModel[]>('/api/bobvale/GetMyContinuesJobs', model);
  }

  getCardUserKey(musteriId: number): Observable<UserKeyRespModel> {
    if (!musteriId) throw new Error('musteriId is required');
    return this._httpClient.get<UserKeyRespModel>('/api/bobvale/GetCardUserKey?musteriid=' + musteriId);
  }

  requestSavedCreditCards(body: SavedCreditCardsReq): Observable<RequestSavedCreditCardsResponse> {
    return this._httpClient.post<RequestSavedCreditCardsResponse>('/api/bobvale/RequestSavedCreditCards', body);
  }

  getInvoiceList(telNo: string): Observable<InvoicesModel[]> {
    if (!telNo) telNo = '0';
    return this._httpClient.get<InvoicesModel[]>('/api/bobvale/GetInvoiceList?telefon=' + telNo);
  }

  iyzicoPayment(body: IyzicoPaymentModel): Observable<IyzicoPaymentResponseModel> {
    return this._httpClient.post<IyzicoPaymentResponseModel>('/api/bobvale/IyzicoPayment', body);
  }

  checkPaymentResult(basketid: string): Observable<any> {
    return this._httpClient.get<any>('/api/bobvale/CheckPaymentResult?basketid=' + basketid);
  }

  getCallVehicleInfo(guid: string | null): Observable<CarSelectModel> {
    guid = guid ? guid : '';
    return this._httpClient.get<CarSelectModel>(`/api/vale/AracimiCagirBilgi/${guid}`);
  }

  getCallCarViaGuid(guid: string, teslimKapisi: number): Observable<CarSelectModel> {
    return this._httpClient.get<CarSelectModel>(`/api/vale/AracimiCagir/${guid}/${teslimKapisi}`);
  }

  checkAdvantageValueOthersNew(model: AdvantageModelOther): Observable<AdvantageResult> {
    return this._httpClient.post<AdvantageResult>('/api/bobvale/CheckAdvantageValueOthersNew', model);
  }

  checkAdvantageValueNew(model: AdvantageModel): Observable<AdvantageResult> {
    return this._httpClient.post<AdvantageResult>('/api/bobvale/CheckAdvantageValueNew', model);
  }

  checkAdvantageValue(model: AdvantageModel): Observable<AdvantageResult> {
    return this._httpClient.post<AdvantageResult>('/api/bobvale/CheckAdvantageValue', model);
  }

  checkAdvantageValueOthers(model: AdvantageModelOther): Observable<AdvantageResult> {
    return this._httpClient.post<AdvantageResult>('/api/bobvale/CheckAdvantageValueOthers', model);
  }

  getVehicleList(musteriId: number): Observable<GetVehicleListModel[]> {
    return this._httpClient.get<any>('/api/bobvale/GetVehicleList?musteriId=' + musteriId).pipe();
  }

  deleteVehicle(body: DeleteVehicleModel): Observable<boolean> {
    return this._httpClient.post<boolean>('/api/bobvale/DeleteVehicle', body).pipe();
  }

  vehicleProcess(funcName: string, body: VehicleModel): Observable<BaseResponse<any>> {
    return this._httpClient.post<BaseResponse<any>>('/api/bobvale/' + funcName, body).pipe();
  }

  getVehicleBrands(): Observable<VehicleBrands[]> {
    return this._httpClient.get<VehicleBrands[]>('/api/bobvale/GetVehicleBrands').pipe();
  }

  getVehicleModels(markaId: string): Observable<VehicleModels[]> {
    return this._httpClient.get<VehicleModels[]>('/api/bobvale/GetVehicleModels?markaId=' + markaId).pipe();
  }

  getVehicleColors(): Observable<VehicleColors[]> {
    return this._httpClient.get<VehicleColors[]>('/api/bobvale/GetVehicleColors').pipe();
  }
}
