export class RegistrationReq {
  public name: string;
  public lastname: string;
  public email: string;
  public password: string | null;
  public phonenumber: string;
  public gender: number;
  public birthdate: Date | undefined;
  public plate: string;
  public reklamPazarlamaOnay: boolean;
  public genelAydinlatmaMetniOnay: boolean;
  public invitationCode: number;
  public language: string;

  constructor(item?: any) {
    this.name = item?.name || undefined;
    this.lastname = item?.lastname || undefined;
    this.email = item?.email || undefined;
    this.password = item?.password || undefined;
    this.phonenumber = item?.phonenumber || undefined;
    this.gender = item?.gender || undefined;
    this.birthdate = item?.birthdate || undefined;
    this.plate = item?.plate || undefined;
    this.reklamPazarlamaOnay = item?.conditions || false;
    this.genelAydinlatmaMetniOnay = item?.dataUsage || false;
    this.invitationCode = item?.invitationCode || undefined;
    this.language = item?.language || undefined;
  }
}

export class RegisterCustomerDataModel {
  public musteriId: number;
  public phonenumber: string;
  public transferUserId: number;

  constructor(item?: any) {
    this.musteriId = item.musteriId || undefined;
    this.phonenumber = item.phonenumber || undefined;
    this.transferUserId = item.transferUserId || undefined;
  }
}
