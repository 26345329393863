import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, timeout } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GoogleService {
  private _googleMapFlag = new BehaviorSubject<boolean>(false);
  private googleMapFlag$ = this._googleMapFlag.asObservable();

  constructor(private httpClient: HttpClient) {}

  public GoogleInit(): Observable<boolean> {
    return new Observable(observer => {
      this.getGoogleJs();
      this.googleMapFlag$.subscribe(flag => {
        observer.next(flag);
      });
    });
  }

  private async getGoogleJs() {
    const scriptUrl = 'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyCyQWxs2cQUciOJ27VYqgyTxdCQ1PWSkWQ';

    this.httpClient.jsonp(scriptUrl, 'callback').subscribe({
      next: () => {
        this._googleMapFlag.next(true);
        console.log('googlemap true');
      },
      error: e => {
        console.log('googlemap false');
        this._googleMapFlag.next(false);
        setTimeout(() => {
          this.getGoogleJs();
        }, 5000);
      }
    });
  }
}
