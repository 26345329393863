import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { LandingMenuModel } from '../models/mobile.model';

@Injectable({
  providedIn: 'root'
})
export class MobileService {
  private menu = new BehaviorSubject<LandingMenuModel[]>([]);
  public menu$ = this.menu.asObservable();

  constructor(private _httpClient: HttpClient) {}

  public async getLandingMenu(): Promise<void> {
    const data = await lastValueFrom(this._httpClient.get<LandingMenuModel[]>('/api/mobile/GetLandingMenu'));

    this.menu.next(data.filter(f => f.enable));
  }
}
