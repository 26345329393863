export const environment = {
  firebase: {
    projectId: 'bob-app-776f7',
    appId: '1:314464263723:web:8facb0c72bf9fe3ed9d46e',
    storageBucket: 'bob-app-776f7.appspot.com',
    apiKey: 'AIzaSyAjCCvlih2iMYdLRlQZjqKYosbBRb4UZBI',
    authDomain: 'bob-app-776f7.firebaseapp.com',
    messagingSenderId: '314464263723',
    measurementId: 'G-RVL35LX00P'
  },
  production: true,
  newApi: 'https://testapi.bob.com.tr'
};

// export const environment = {
//   production: false,
//   newApi: 'https://localhost:7012'
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
export const APP_STORE_URL = 'https://apps.apple.com/tr/app/bob/id1539323672?l=tr';
export const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.bobvale.app';
export const SERVICE_TYPE = {
  TRANSFER: 0,
  VALE: 1,
  CAR_PARK: 2,
  CAR_WASH: 3,
  VIP_PARK: 4,
  ANTIVIRUS: 5,
  INSURANCE: 6,
  LOUNGE: 7
};
export const TRANSFER_SERVICE_TYPE = {
  TRANSFER: 'transfer',
  BY_THE_HOUR: 'rent'
};

export const DEFAULT_LAT = 41.078173;
export const DEFAULT_LNG = 29.012952;

export const DEFAULT_MAP_CENTER = 'AIzaSyCIGDItZqV4HOaf1JhRKWMC43F0-SRUEIE';
