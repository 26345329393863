export interface CheckRewardProgramNewRequestModel {
  userId: number;
  rewardProgramId?: number | undefined;
  code: string;
  price: number;
}

export interface CheckRewardProgramNewResponseModel {
  isOk: boolean;
  transactionId: string;
  returnMessage: string;
  message: string;
  messageEn: string;
  price: number;
}
export class PassengerListModel {
  public email: string | undefined;
  public nameLastName: string;
  public nationalityId: string;
  public passengerNationality: string;
  public passengerType: number;
  public phone: string;
  public gender: number | undefined;

  constructor(item?: any) {
    this.email = item?.email || undefined;
    this.nameLastName = item?.nameLastName || undefined;
    this.nationalityId = item?.nationalityId || undefined;
    this.passengerNationality = item?.passengerNationality || undefined;
    this.passengerType = item?.passengerType || undefined;
    this.phone = item?.phone || undefined;
    this.gender = item?.gender || undefined;
  }
}

export interface ReservationDetailModel {
  adultCount: number;
  childCount: number;
  currency: string;
  description: string;
  flightNo: string;
  fromAddress: string;
  fromLatitude: number;
  fromLongitude: number;
  fromRegion: string;
  language: string;
  languageID: number;
  price: number;
  reservationDate: string;
  serviceType: number;
  tempId: number;
  toAddress: string;
  toLatitude: number;
  toLongitude: number;
  toRegion: string;
  totalCost: number;
  transferKm: number;
  vatRatio: number;
  serviceId: number;
  vehicleTypeId: number;
  departure_Landing: string;
}

export interface AddReservationModel {
  cardToken: string | null;
  creditCardId: number;
  description: string;
  discountAmount: number;
  discountExists: boolean;
  discountRate: number;
  extraServiceList: any[];
  invoiceAddressId: number;
  paidRoute: any[];
  passengerList: PassengerListModel[];
  reservationDetail: ReservationDetailModel;
  rewardProgramCode: string;
  rewardProgramId: number;
  rewardProgramTransactionId: string;
  totalCost: number;
  userId: number | undefined;
  isCuzdan: boolean;
}

export interface AddReservationDataRespModel {
  reservationId: number;
  reservationNo: string;
  message: string;
  messageEn: string;
  responseCode: number;
  userId: number;
  htmlContentFor3DSecure: string;
}

export interface KopruOtoyolDataModel {
  ad: string;
  boylam: number;
  enlem: number;
  gorunur: boolean;
  id: number;
  silindi: boolean;
}

export interface JobModelReq {
  userid: number;
  telefon: string;
  pagenumber: number;
  datacount: number;
}
